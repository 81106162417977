import RenLink from "../shared/RenLink";
import Quote from "./quote";
import ImagePortableText from "./image.portableText";
import ImageGallery from "../media/image.gallery";
import BlockContent from "@sanity/block-content-to-react";
import Table from "./table";
import Expandable from "./expandable";
import CardEmployeeListing from "../employee/card.employee.listing";

const TextTwoColumns = ({ columns, showCategories = false }) => (
  <section className="o-grid o-wrapper--narrow">
    {columns.map((column, index) => (
      <div
        className=" o-grid__item o-grid__item--half c-portableText"
        key={index}
      >
        <BlockContent
          blocks={column}
          serializers={{
            types: {
              imageGallery: props => (
                <ImageGallery images={props.node.imageGalleryArray} />
              ),
              quote: props => (
                <Quote
                  author={props.node.quoteAuthor}
                  quote={props.node.quoteText}
                  image={props.node.image}
                  className="u-margin-top-bottom--large"
                />
              ),
              imageRich: props => (
                <ImagePortableText
                  caption={props.node.caption}
                  alt={props.node.alt}
                  image={props.node.asset}
                />
              ),
              personListing: props => (
                <CardEmployeeListing
                  header={props.node.personListingTitle}
                  persons={props.node.personListingList}
                  showCategories={showCategories}
                />
              ),
              expandable: props => (
                <Expandable
                  title={props.node.expandableTitle}
                  content={props.node.expandableContent}
                  leftIcon={props.node.leftIcon ? true : false}
                />
              ),
              tableField: props => (
                <div className="o-wrapper--medium u-padding-top-bottom--larger">
                  <Table table={props.node} />
                </div>
              ),
              link: props => (
                <RenLink
                  href={props.node.url || props.node.internalPage}
                  classes="c-button"
                >
                  {props.node.text}
                </RenLink>
              ),

              block: props => {
                return BlockContent.defaultSerializers.types.block(props);
              },
            },
            marks: {
              link: props => {
                const {
                  mark: { internalPage, url, text },
                } = props;
                return (
                  <RenLink
                    href={url || internalPage}
                    classes="c-portableText__link"
                  >
                    {text}
                  </RenLink>
                );
              },
            },
          }}
        />
      </div>
    ))}
  </section>
);

export default TextTwoColumns;
