import Image from "./image";
import PropTypes from "prop-types";
import { sortImages } from "../../helpers/image.gallery.sorter";

const ImageGallery = ({ images }) => {
  const [builtImageObjects, builtCaption] = sortImages(images);
  return (
    <div className="c-image-gallery">
      {builtImageObjects.map((imageObj, index) => {
        switch (imageObj.title) {
          case "twoTall":
            return (
              <TwoTall images={[imageObj.img1, imageObj.img2]} key={index} />
            );
          case "oneTallTwoWide":
            return (
              <OneTallTwoWide
                images={[imageObj.img1, imageObj.img2, imageObj.img3]}
                key={index}
              />
            );
          case "twoWide":
            return (
              <TwoWide images={[imageObj.img1, imageObj.img2]} key={index} />
            );
          default:
            return <OneWide image={imageObj.img1} key={index} />;
        }
      })}
      {builtCaption && (
        <p className="c-image-gallery__caption">{builtCaption}</p>
      )}
    </div>
  );
};

const OneWide = ({ image }) => (
  <figure className="c-image-gallery__image-wrapper">
    <Image
      image={image}
      interceptBuilder={builder => builder.width(1200).height(700).fit("crop")}
      alt={image.alt}
      className="c-image-gallery__image"
    />
  </figure>
);

const TwoWide = ({ images }) => (
  <div className="c-image-gallery">
    {images.map((image, index) => (
      <figure
        className="c-image-gallery__image-wrapper c-image-gallery__image-wrapper--two-wide"
        key={index}
      >
        <Image
          image={image}
          interceptBuilder={builder =>
            builder.width(1200).height(700).fit("crop")
          }
          alt={image.alt}
          className="c-image-gallery__image"
        />
      </figure>
    ))}
  </div>
);

const TwoTall = ({ images }) => (
  <div className="c-image-gallery">
    {images.map((image, index) => (
      <figure
        className="c-image-gallery__image-wrapper c-image-gallery__image-wrapper--two-tall"
        key={index}
      >
        {image && (
          <Image
            image={image}
            interceptBuilder={builder =>
              builder.width(600).height(820).fit("crop")
            }
            alt={image.alt}
            className="c-image-gallery__image"
          />
        )}
      </figure>
    ))}
  </div>
);

const OneTallTwoWide = ({ images }) => {
  let tallImage = images.shift();
  return (
    <div className="c-image-gallery">
      <figure className="c-image-gallery__image-wrapper c-image-gallery__image-wrapper--two-tall">
        <Image
          image={tallImage}
          interceptBuilder={builder =>
            builder.width(600).height(820).fit("crop")
          }
          alt={tallImage.alt}
          className="c-image-gallery__image"
        />
      </figure>

      <div className="c-image-gallery__image-wrapper c-image-gallery__image-wrapper--one-tall-two-wide">
        {images.map((image, index) => (
          <Image
            image={image}
            interceptBuilder={builder =>
              builder.width(1200).height(800).fit("crop")
            }
            alt={image.alt}
            className={"c-image-gallery__image"}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

ImageGallery.propTypes = {
  images: PropTypes.array,
};

export default ImageGallery;
