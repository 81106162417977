import PropTypes from "prop-types";
import BlockContent from "@sanity/block-content-to-react";
import ExpandIcon from "components/icons/expand.icon";
import { useState } from "react";
import BeforeExpandableIcon from "../icons/before.expandable.icon";

const Expandable = ({ title, content, leftIcon, className }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={className ? "c-expandable " + className : "c-expandable"}>
      <button className="c-expandable-title" onClick={() => setOpen(!open)}>
        {leftIcon && (
          <span style={{ marginTop: "3px" }}>
            <BeforeExpandableIcon />
          </span>
        )}
        <div className="c-expandable-title-text-element">{title}</div>
        <div
          className={open ? "c-expandable-icon--expanded" : "c-expandable-icon"}
        >
          <ExpandIcon />
        </div>
      </button>
      <div
        className={
          open
            ? leftIcon
              ? "c-expandable--leftIcon"
              : "c-expandable--no-leftIcon"
            : "u-hide"
        }
      >
        <BlockContent blocks={content} />
      </div>
    </div>
  );
};

Expandable.propTypes = {
  title: PropTypes.string,
  leftIcon: PropTypes.bool,
  content: PropTypes.array,
  className: PropTypes.string,
};

export default Expandable;
