import Image from "../media/image";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

const Advertisement = ({ ads }) => {
  let [ad, setAd] = useState(ads[Math.floor(Math.random() * ads.length)]);
  useEffect(() => {
    setAd(ads[Math.floor(Math.random() * ads.length)]);
  }, []);

  return (
    <section className="o-wrapper--narrow u-margin-top-bottom--large advertisement">
      <p className="c-advertisement__tag">Annonse</p>
      <a className="c-advertisement u-block " href={ad.adLink || ""}>
        {ad.image && (
          <Image
            image={ad.image}
            className="u-max-width-full"
            alt={ad.image.alt}
            interceptBuilder={builder => builder.width(600)}
          />
        )}
      </a>
    </section>
  );
};

Advertisement.propTypes = {
  link: PropTypes.string,
  image: PropTypes.object,
};
export default Advertisement;
