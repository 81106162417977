function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

function twoTall(tall1, tall2, master) {
  master.push({ title: "twoTall", img1: tall1, img2: tall2 });
}

function oneTallTwoWide(tall, wide1, wide2, master) {
  master.push({
    title: "oneTallTwoWide",
    img1: tall,
    img2: wide1,
    img3: wide2,
  });
}

function twoWide(wide1, wide2, master) {
  master.push({ title: "twoWide", img1: wide1, img2: wide2 });
}

function oneWide(wide1, master) {
  master.push({ title: "oneWide", img1: wide1 });
}

function sortImages(images) {
  const wides = [];
  const talls = [];
  const master = [];
  const captions = [];
  images.map(image => {
    if (image.caption) {
      captions.push(image.caption);
    }
  });

  const reducedCaptions =
    captions.length > 0
      ? captions.reduce((acc, curr) => acc + " " + curr)
      : null;

  images.map(image => {
    image.asset.metadata.dimensions.aspectRatio > 0.9
      ? wides.push(image)
      : talls.push(image);
  });

  let i = 0;
  while (wides.length > 2 && talls.length > 0) {
    i % 2 == 0 && talls.length >= 2
      ? twoTall(talls.pop(), talls.pop(), master)
      : oneTallTwoWide(talls.pop(), wides.pop(), wides.pop(), master);
    i++;
  }
  // 2 wides remaining // 1 wide remaining // Talls empty

  //Ensure we're out of talls
  while (talls.length > 0) {
    if (talls.length % 2 == 1 && wides.length == 2) {
      oneTallTwoWide(talls.pop(), wides.pop(), wides.pop(), master);
    } else if (talls.length % 2 == 1 && wides.length == 1) {
      twoWide(talls.pop(), wides.pop(), master);
    } else if (talls.length % 2 == 1 && wides.length == 0) {
      oneWide(talls.pop(), master);
    } else {
      twoTall(talls.pop(), talls.pop(), master);
    }
  }

  //Create wides until we're out of images
  i = 0;
  while (wides.length > 0) {
    i % 2 == 0 && wides.length > 1
      ? twoWide(wides.pop(), wides.pop(), master)
      : oneWide(wides.pop(), master);
    i++;
  }
  shuffle(master);
  return [master, reducedCaptions];
}

export { sortImages };
