import Image from "../media/image";
import PropTypes from "prop-types";

const getImageDimensions = image => {
  const defaults = { width: 720, height: 800 };

  if (!image?.metadata) {
    return defaults;
  }
  return {
    width:
      image.metadata.dimensions.width > 720
        ? defaults.width
        : image.metadata.dimensions.width,
    height:
      image.metadata.dimensions.height > 800
        ? defaults.height
        : image.metadata.dimensions.height,
  };
};

// NOTE: This function presents images in both in RENblad and on the website
const ImagePortableText = ({ image, caption = "", alt, textWidth }) => {
  const { width, height } = getImageDimensions(image);
  return (
    <figure className="c-portableText--image-wrapper">
      <Image
        className="c-portableText--image"
        alt={alt}
        image={image}
        interceptBuilder={builder =>
          textWidth ? builder.width(width) : builder.height(height)
        }
      />
      {caption && (
        <figcaption className="u-fontSmall u-margin--rm">{caption}</figcaption>
      )}
    </figure>
  );
};

ImagePortableText.propTypes = {
  image: PropTypes.object,
  caption: PropTypes.string,
  alt: PropTypes.string,
  textWidth: PropTypes.bool,
  description: PropTypes.string,
};

export default ImagePortableText;
